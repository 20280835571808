import useQuasar from 'quasar/src/composables/use-quasar.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';;

export default function () {
  const $q = useQuasar();
  function qLoadingShow(message = "Actualizando información") {
    $q.loading.show({
      delay: 400, // ms
      spinner: QSpinnerGears,
      spinnerColor: "rgba(255, 255, 255, 0.99)",
      messageColor: "rgba(255, 255, 255, 0.99)",
      backgroundColor: "primary",
      message: "<span class='text-h6'><b>" + message + "</b></span>",
      html: true,
      sanitize: true,
    });
  }
  function qLoadingHide() {
    $q.loading.hide();
  }
  function qNotifySucces(message = "SUCCES") {
    $q.notify({
      icon: "done",
      color: "primary",
      message: message,
    });
  }
  function qNotifyError(message = "ERROR") {
    $q.notify({
      icon: "error",
      color: "negative",
      message: message,
    });
  }
  return {
    $q,
    qLoadingShow,
    qLoadingHide,
    qNotifySucces,
    qNotifyError,
  };
}
