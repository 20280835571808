export const createMetaDataFormat = (data) => {
  let keys = JSON.parse(JSON.stringify(data[0])),
    i = 0,
    k = 0,
    obj = null,
    output = [];
  for (i = 1; i < data.length; i++) {
    obj = {};
    for (k = 0; k < keys.length; k++) {
      obj[keys[k]] = data[i][k];
    }
    output.push(obj);
  }
  return output;
};

// For ag-Grid
export const createColumsFormat = (data, metaData) => {
  window.moment.locale("es-mx");
  let i = 0;
  let output = [];
  let obj = null;
  for (i = 0; i < data.length; i++) {
    // console.log(JSON.stringify(metaData[i], null, "\t"));
    obj = {};
    obj["field"] = data[i];
    // obj["filter"] = metaData[i].agFilterType;
    obj["filter"] = "agMultiColumnFilter";
    obj["floatingFilter"] = true;
    obj["filterParams"] = {
      filters: [
        {
          filter: metaData[i].agFilterType,
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    };
    if (metaData[i].agFilterParams) {
      obj["filterParams"]["filters"][0]["filterParams"] = {
        comparator: eval(`(filterLocalDateAtMidnight, cellValue) => {
          window.moment.locale("es-mx");
          const dateAsString = cellValue;
          if (dateAsString === null) {
            return 0;
          }
          const cellDate = new Date(
            window.moment(dateAsString, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("YYYY"),
            window.moment(dateAsString, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM") - 1,
            window.moment(dateAsString, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD")
          );
          // Now that both parameters are Date objects, we can compare
          // console.log("cellDate: " + cellDate);
          // console.log("filterLocalDateAtMidnight: " + filterLocalDateAtMidnight);
          if (cellDate < filterLocalDateAtMidnight) {
            // console.log("<");
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            // console.log(">");
            return 1;
          }
          // console.log("0");
          return 0;
        }`),
      };
      obj["valueFormatter"] = eval(`(params) => {
        // console.log(window.moment.locale());
        window.moment.locale("es-mx");
        const formatDate = window.moment(params.value, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("YYYY-MM-DD HH:mm:ss");
        return formatDate;
      }`);
      obj["filterParams"]["filters"][1]["filterParams"] = {
        valueFormatter: obj["valueFormatter"],
      };
    }
    if (i === 0) {
      obj["checkboxSelection"] = true;
      obj["sort"] = "desc";
    }
    output.push(obj);
  }
  return output;
};

export const createRowsFormat = (data) => {
  let keys = JSON.parse(JSON.stringify(data[0])),
    i = 0,
    k = 0,
    obj = null,
    output = [];
  for (i = 1; i < data.length; i++) {
    obj = {};
    for (k = 0; k < keys.length; k++) {
      // if (metaData[k].agFilterParams) {
      //   console.log(metaData[k].agFilterParams);
      //   obj[keys[k]] = new Date(
      //     window.moment(data[i][k], "YYYY-MM-DDTHH:mm:ss.SSSZ")
      //   );
      // } else {
      //   obj[keys[k]] = data[i][k];
      // }
      obj[keys[k]] = data[i][k];
    }
    output.push(obj);
  }
  return output;
};

// For Quasar Datatable
// export const createRowsFormat = (data) => {
//   let keys = JSON.parse(JSON.stringify(data[0])),
//     i = 0,
//     k = 0,
//     obj = null,
//     output = [];
//   keys[0] = "name";
//   for (i = 1; i < data.length; i++) {
//     obj = {};
//     for (k = 0; k < keys.length; k++) {
//       obj[keys[k]] = data[i][k];
//     }
//     output.push(obj);
//   }
//   return output;
// };

// For Quasar Datatable
// export const createColumsFormat = (data) => {
//   let i = 0;
//   let output = [];
//   let obj = null;
//   for (i = 0; i < data.length; i++) {
//     obj = {};
//     obj["name"] = data[i];
//     obj["label"] = data[i];
//     obj["field"] = eval(`(row) => { return row[Object.keys(row)[${i}]] }`);
//     obj["sortable"] = true;
//     // Logger.log("obj: " + JSON.stringify(obj));
//     output.push(obj);
//   }
//   return output;
// };
